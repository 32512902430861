import React, { useState, useRef, useEffect } from 'react';
import moment from 'moment';
import * as R from 'ramda';
import { connect, ConnectedProps } from 'react-redux';
import { useFieldArray, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { Row } from '@common/components/form';
import { PollInputHookForm, TPollForm } from '@common/components/form/inputs/poll';
import { CheckboxReverseInputHookForm } from '@common/components/form/inputs/checkbox-reverse/checkbox-reverse';
import { FileInputMultipleHookForm } from '@common/components/form/inputs/file/file';
import { MarkupEditorHookForm } from '@common/components/form/inputs/markup';
import * as alert from '@common/services/alert';
import UserInput from '@common/components/user-input';
import Card from '@common/components/card';
import { Modal } from '@common/components/modal';
import Alert from '@common/components/alert';
import Icon from '@common/components/icon';
import { Button } from '@common/components/button';

import { useThunkDispatch } from '@common/hooks';
import { setFormFileAttachments } from '@common/components/form/utils';
import { getFilesFromClipboard } from '@common/utils/get-files-from-clipboard';
import postMessage from '../../actions/post-message';
import * as userSelector from '../../../core/selectors/logged-user';
import { validateAttachments, validatePoll } from '../validators/message';
import { ALL_ACCEPT } from '@common/components/form/inputs/file/utils';

import { EParentTypes, EPermissions } from '@common/definitions';
import { ETimelineTypes } from '../../definitions';
import type { StoreState } from '@common/types/store';
import type { LinkPreview, LoggedUser } from '@common/types/objects';
import { DateTimeInputHookForm } from '@common/components/form/inputs/datetime';
import { PostMessagePayload, PostMessageValues } from '@modules/social/actions/post-message.types';
import { AttachmentsHookForm } from '@common/components/form/attachments';

const maxAmountOfFiles = 10;

type PostMessageFormValues = {
  text: string;
  attachments: any[];
  poll?: TPollForm;
  showPoll: boolean;
  // Can't assign Moment type, see https://github.com/react-hook-form/react-hook-form/issues/4704
  // Also see https://linear.app/oneteam/issue/ONE-1060/dateinput-remove-dependency-on-momentjs
  // on why we should use strings for input instead of Moment
  publish_at: any | null;
  publish_at_value: any;
  is_important: boolean;
  can_comment: boolean;
  link_preview: LinkPreview | null;
};

type Channel = {
  id: string;
  type: EParentTypes;
  parent: {
    id: string;
  };
};

type PostMessageOwnProps = {
  loggedUser: LoggedUser;
  channel: Channel; // Currently selected feed
  timeline: Channel; // Timeline user is trying to post in
  isFocused: boolean;
  postPostMessage: () => void;
  onSelectChannel: (type: string, id: string) => void;
};

const calculateMarkAsImportant = (timeline: Channel, permissions: string[]) => {
  if (timeline.type === ETimelineTypes.CHANNEL) {
    // @ts-expect-error
    return timeline.parent.settings.is_moderator || permissions.includes(EPermissions.ORGANISATION_FEED_MESSAGES_MARK_IMPORTANT);
  } if (timeline.type === ETimelineTypes.NETWORK || timeline.type === ETimelineTypes.TEAM) {
    return permissions.includes(EPermissions.NETWORK_FEED_MESSAGES_MARK_IMPORTANT);
  }

  return false;
};

const PostMessageFormUnconnected = ({
  organisationId,
  loggedUser,
  timeline,
  channel,
  isFocused,
  permissions,
  postPostMessage,
  onSelectChannel,
}: PostMessageOwnProps & ConnectedProps<typeof reduxConnector>) => {
  const thunkDispatch = useThunkDispatch();
  const { t } = useTranslation();
  const mentionRef = useRef<any>();
  const [canMarkAsImportant, setCanMarkAsImportant] = useState(calculateMarkAsImportant(timeline, permissions));
  const [isScheduleModalVisible, setIsScheduleModalVisible] = useState(false);

  const {
    control, watch, setValue, formState, handleSubmit, reset,
  } = useForm<PostMessageFormValues>({
    mode: 'all',
    defaultValues: {
      text: '',
      attachments: [],
      link_preview: null,
      poll: {
        question: '',
        options: [],
        is_multiple_choice: false,
        show_votes: true,
        expires_at: false,
      },
      showPoll: false,
      is_important: false,
      can_comment: true,
      publish_at: null,
      publish_at_value: moment(),
    }
  });
  const { isValid, isSubmitting } = formState;
  const values = watch();

  const onSave = handleSubmit(async (formValues) => {
    const data: PostMessageValues = {
      ...formValues,
      attachments: formValues.attachments,
      poll: formValues.showPoll ? formValues.poll : undefined,
      link_preview: R.isEmpty(formValues.attachments) ? formValues.link_preview : undefined,
    };
    const shouldAppend = (channel.type === ETimelineTypes.FEED)
      ? true
      : (timeline.type === channel.type && timeline.id === channel.id);

    let endpoint = '';
    if (timeline.type === ETimelineTypes.NETWORK) {
      endpoint = `/v3/networks/${timeline.parent.id}/messages`;
    } else if (timeline.type === ETimelineTypes.TEAM) {
      endpoint = `/v3/teams/${timeline.parent.id}/messages`;
    } else if (timeline.type === ETimelineTypes.CHANNEL) {
      endpoint = `/v1/organisations/${organisationId}/channels/${timeline.parent.id}/messages`;
    } else if (timeline.type === ETimelineTypes.EVENT) {
      endpoint = `/v1/organisations/${organisationId}/events/${timeline.parent.id}/messages`;
    }

    const payload: Partial<PostMessagePayload> = {
      text: data.text,
      files: R.pluck('id', data.attachments),
      publish_at: data.publish_at ? data.publish_at.toISOString() : null,
      is_important: !!values.is_important,
      can_comment: !!values.can_comment,
    };

    if (data.poll && formValues.poll) {
      payload.poll_question = formValues.poll.question;
      payload.poll_options = R.pipe(R.pluck('value'), R.reject(R.isEmpty))(formValues.poll.options);
      payload.poll_is_multiple_choice = formValues.poll.is_multiple_choice;
      payload.poll_show_votes = formValues.poll.show_votes;
      if (formValues.poll.expires_at) {
        payload.poll_expires_at = formValues.poll.expires_at;
      }
    }

    try {
      await thunkDispatch(postMessage(payload, endpoint, shouldAppend));
      reset();
      if (!shouldAppend) onSelectChannel(timeline.type, timeline.id);
      postPostMessage();
    } catch (response: any) {
      alert.forStatus(response.status_code, {
        warning: t('social:form_post_message_warning_posting_message'),
        error: t('social:form_post_message_error_posting_message'),
      });
    }
  });

  const handleTogglePoll = () => setValue('showPoll', !values.showPoll);
  const handleToggleImportant = () => setValue('is_important', !values.is_important);
  const handleAddMention = () => mentionRef.current?.();
  const handleSetPublishAtValue = () => {
    if (values.publish_at_value) setValue('publish_at', values.publish_at_value);
    setIsScheduleModalVisible(false);
  };

  // When user switches timeline, recalculate wether user has permssion to mark message as important
  useEffect(() => {
    const hasPermission = calculateMarkAsImportant(timeline, permissions);
    setCanMarkAsImportant(hasPermission);
  }, [timeline, permissions]);

  const attachments = useFieldArray({
    name: 'attachments',
    rules: { validate: validateAttachments },
    control,
  });
  const { insert, update } = attachments;

  const handleAddImageFromClipboard = (e: ClipboardEvent) => {
    const files = getFilesFromClipboard(e);
    if (!R.isEmpty(files)) {
      e.preventDefault();

      setFormFileAttachments(
        files,
        values.attachments,
        organisationId,
        (file, index) => {
          if (index) {
            if (file instanceof File) insert(index, file);
            else update(index, file);
          }
        },
        t,
        { processFile: true, preview: true },
      );
    }
  };

  return (
    <>
      <Card.Content>
        <Row>
          <UserInput user={loggedUser}>
            {(values.is_important && (
              <div className="PostMessage__Important">
                <Icon type="star__filled" className="PostMessage__Important__Icon" />
                <Trans i18nKey="social:feed_item_message_important" />
              </div>
            )) || null}
            <MarkupEditorHookForm
              name="text"
              rules={{ required: !validatePoll(values.poll) && !values.attachments.length }}
              control={control}
              dispatchUpdatesOnChange
              target={timeline}
              onPaste={handleAddImageFromClipboard}
              addMentionRef={mentionRef}
              useLinkPreview={R.isEmpty(values.attachments)}
              onLinkPreviewChanged={(linkPreview) => setValue('link_preview', linkPreview)}
            />
          </UserInput>
        </Row>

        <AttachmentsHookForm
          {...attachments}
          addInput={(
            <FileInputMultipleHookForm
              multiple={maxAmountOfFiles > 1}
              processFile
              accept="image/*, video/*"
              {...attachments}
            >
              <Icon type="add" />
            </FileInputMultipleHookForm>
          )}
        />

        {values.showPoll && (
          <PollInputHookForm
            name="poll"
            control={control}
            closePoll={handleTogglePoll}
            rules={{
              validate: validatePoll,
            }}
          />
        )}

        {values.is_important && (
          <Alert type="info">
            <Trans i18nKey="social:post_message_important_explanation" />
          </Alert>
        )}

        <div className="PostMessage__Actions">
          <Button
            className="PostMessage__Button--poll"
            icon="poll__filled"
            onClick={handleTogglePoll}
            active={values.showPoll}
          >
            <Trans i18nKey="social:post_message_poll" />
          </Button>
          <FileInputMultipleHookForm
            unsplash
            reverseTabs
            dragndrop
            modal
            multiple={maxAmountOfFiles > 1}
            processFile
            accept="image/*, video/*"
            maxFileSize={500}
            {...attachments}
          >
            <Button className="PostMessage__Button--attachment" icon="image__filled">
              <Trans i18nKey="social:post_message_attachment_photo_video" />
            </Button>
          </FileInputMultipleHookForm>
          <FileInputMultipleHookForm
            multiple={maxAmountOfFiles > 1}
            processFile
            accept={ALL_ACCEPT}
            {...attachments}
          >
            <Button className="PostMessage__Button--document" icon="document__filled">
              <Trans i18nKey="social:post_message_attachment_document" />
            </Button>
          </FileInputMultipleHookForm>
          {isFocused ? (
            <>
              <Button
                className="PostMessage__Button--tag"
                icon="person__filled"
                onClick={handleAddMention}
              >
                <Trans i18nKey="social:post_message_tag" />
              </Button>
              {canMarkAsImportant && (
                <Button
                  className="PostMessage__Button--important"
                  icon="star__filled"
                  onClick={handleToggleImportant}
                  active={values.is_important}
                >
                  <Trans i18nKey="social:post_message_important" />
                </Button>
              )}
            </>
          ) : (
            <Button icon="more_horiz" />
          )}
        </div>
      </Card.Content>

      {values.publish_at && (
        <div className="PostMessage__PublishAt">
          <Trans
            i18nKey="social:form_post_message_planned_for"
            values={{ datetime: values.publish_at.format(`dddd D MMM [${t('social:form_post_message_at')}] HH:mm`) }}
          />
          <div
            className="pull-right"
            onClick={() => setValue('publish_at', null)}
          >
            <Trans i18nKey="social:form_post_message_remove" />
          </div>
        </div>
      )}

      <Card.Footer>
        <CheckboxReverseInputHookForm
          name="can_comment"
          label={t('social:post_message_disable_comments')}
          control={control}
        />
        <div className="pull-right">
          <Modal
            list
            show={isScheduleModalVisible}
            size="small"
            className="modal-datetimepicker modal--color-grey Form"
            onClose={() => setIsScheduleModalVisible(false)}
            content={(
              <DateTimeInputHookForm
                name="publish_at_value"
                control={control}
              />
            )}
            footer={(
              <Button
                type="primary"
                onClick={handleSetPublishAtValue}
              >
                <Trans i18nKey="social:form_post_message_schedule" />
              </Button>
            )}
          >
            <Button
              icon="access_time"
              type="primary"
              disabled={!isValid}
              onClick={() => setIsScheduleModalVisible(true)}
            />
          </Modal>
          <Button
            type="primary"
            isLoading={isSubmitting}
            disabled={!isValid}
            onClick={onSave}
          >
            {values.publish_at
              ? t('social:form_post_message_schedule')
              : t('social:form_post_message_post')}
          </Button>
        </div>
      </Card.Footer>
    </>
  );
};

const mapStateToProps = (state: StoreState) => ({
  formValues: (state.form['post-message'] || {}).values,
  permissions: userSelector.permissions(state),
  organisationId: state.organisation.selected.id,
});

const reduxConnector = connect(mapStateToProps);

export const PostMessageForm = reduxConnector(PostMessageFormUnconnected);
