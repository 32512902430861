import Api from '@common/services/api';

export const SOCIAL_POST_MESSAGE = 'social/POST_MESSAGE';

export default (payload, endpoint, shouldAppend) => async (dispatch) => {
  const { data: message } = await Api.post(endpoint, payload);

  dispatch({
    type: SOCIAL_POST_MESSAGE,
    append: shouldAppend,
    scheduled: !!payload.publish_at,
    message,
  });
};
