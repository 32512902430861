import Api from '../../../common/services/api';

export const SOCIAL_VOTE_POLL = 'social/VOTE_POLL';

export default (pollId, vote, optionId, optionIds, messageId) => async (dispatch, getState) => {
  const { network: { selected } } = getState();
  Api.post(`/v2/networks/${selected}/polls/${pollId}/vote`, {
    option_ids: optionIds,
  });
  return dispatch({
    type: SOCIAL_VOTE_POLL,
    pollId,
    messageId,
    optionIds,
    vote,
    optionId,
  });
};
